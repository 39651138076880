var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.permissions.c)?_c('button-top',{attrs:{"must-icon":false,"tooltip":"Crear empaque","name":"Crear"},on:{"action":_vm.store}}):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{staticClass:"w-50"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Nombre producto")]),_c('validation-provider',{attrs:{"name":"Nombre producto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-75"},[_c('label',{staticClass:"text-success"},[_vm._v("Dimensiones (Longitud x anchura x profundidad en cm)")]),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Longitud","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"longitude","type":"number","state":errors.length > 0 ? false : null,"name":"longitude"},model:{value:(_vm.form.longitude),callback:function ($$v) {_vm.$set(_vm.form, "longitude", $$v)},expression:"form.longitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"d-flex justify-content-center align-items-center font-weight-bold",attrs:{"cols":"1"}},[_vm._v("X")]),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Anchura","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"broad","type":"number","state":errors.length > 0 ? false : null,"name":"broad"},model:{value:(_vm.form.broad),callback:function ($$v) {_vm.$set(_vm.form, "broad", $$v)},expression:"form.broad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"d-flex justify-content-center align-items-center font-weight-bold",attrs:{"cols":"1"}},[_vm._v("X")]),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Profundidad","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"depth","type":"number","state":errors.length > 0 ? false : null,"name":"depth"},model:{value:(_vm.form.depth),callback:function ($$v) {_vm.$set(_vm.form, "depth", $$v)},expression:"form.depth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Peso (Lbs)")]),_c('validation-provider',{attrs:{"name":"Peso","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"weight","type":"number","state":errors.length > 0 ? false : null,"name":"weight"},model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", $$v)},expression:"form.weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Descripcion")]),_c('validation-provider',{attrs:{"name":"Descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","rows":"3","max-rows":"6"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }